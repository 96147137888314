/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import BackgroundImage from "../../assets/images/backgrounds/hero-background.png";
import Cross from "../../assets/images/hero/close.png";
import Twitter from "../../assets/images/sidebar/twitter.png";
import Discord from "../../assets/images/sidebar/discord.png";
export default function Sidebar({ setShowSidebar }) {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%), url(${BackgroundImage})`,
      }}
      className="absolute z-50 w-screen h-screen bg-cover"
    >
      <div className="w-full p-8 flex items-center justify-end">
        <div
          onClick={() => setShowSidebar(false)}
          className="w-10 h-10 mt-3.5 mr-2 outline flex items-center justify-end"
        >
          <img className="w-10" src={Cross} alt="" />
        </div>{" "}
      </div>{" "}
      <div className="absolute left-0 bottom-0 pl-8 pb-20 flex flex-col gap-y-10">
        <div className="flex flex-col gap-y-5 text-white modius-bold text-2xl">
          <a href="/iama" className="text-hover-red">
            I AM A{" "}
          </a>{" "}
          <a href="/grants" className="text-hover-red">
            Grants{" "}
          </a>{" "}
          <a href="/license" className="text-hover-red">
            license{" "}
          </a>{" "}
          <a href="/contributors" className="text-hover-red">
            Contributors{" "}
          </a>{" "}
          {/* <a href="/#team" className="text-hover-red">
                                                Team
                                              </a> */}{" "}
          <a href="https://daopunks.pallet.com/" className="text-hover-red">
            Job Board{" "}
          </a>{" "}
          <a href="/alumni" className="text-hover-red">
            Cohort Alumni{" "}
          </a>{" "}
          <a href="/gallery" className="text-hover-red">
            Search{" "}
          </a>{" "}
          <a href="https://dao-punks.myshopify.com/" className="text-hover-red">
            Merch{" "}
          </a>{" "}
          <a
            href="https://twitter.com/DAOpunksNFT"
            target="_blank"
            className="text-hover-red"
            rel="noreferrer"
          >
            Contact{" "}
          </a>{" "}
        </div>{" "}
        <div className="flex gap-x-8">
          <a href="https://twitter.com/DAOpunksNFT" target="_blank">
            <img src={Twitter} alt="" />
          </a>{" "}
          <a href="https://discord.gg/svwE28kYBb" target="_blank">
            <img src={Discord} alt="" />
          </a>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
}
