export default function Footer() {
  return (
    <div className="ibm-plex w-full h-max bg-black text-white text-lg flex flex-col items-center justify-center p-6 text-center">
      <a href="https://ipfs.io/ipfs/QmUYjXGixmPPkx6dcPadqHKtjgMpLkU16s2yBedTe8Axxn?filename=DAOpunks%20Digital%20Asset%20Ownership%20License%20(Terms%20and%20Conditions)%20-%20Final%20IPFS.pdf">
        DAOpunks Digital Asset Ownership <br className="lg:hidden" />{" "}
        License(Terms and Conditions){" "}
      </a>{" "}
      &amp;{" "} DAOPunks® - 2021{" "}
    </div>
  );
}
