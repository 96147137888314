import React from "react";
import Header from "../components/global/header";
import BackgroundImage from "../assets/images/backgrounds/hero-background.png";

export default function License() {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%), url(${BackgroundImage})`,
      }}
      className="w-screen h-max bg-cover flex flex-col pb-20"
    >
      <Header />
      <div className="flex flex-col justify-center items-center">
        <div className="modius-bold text-4xl sm:text-5xl md:text-5xl text-white red-text-shadow text-center pt-10 lg:pt-12">
          TERMS <br className="lg:hidden" /> AND <br className="lg:hidden" />{" "}
          CONDITIONS
        </div>
        <p className="w-11/12 mx-10 max-w-4xl mt-5 sm:mt-10 ibm-plex text-base md:text-xl text-center text-white">
          DAOpunks is a collection of digital artworks (NFTs) running on the
          Ethereum network. This website is only an interface allowing
          participants to exchange digital collectibles. Users are entirely
          responsible for the safety and management of their own private
          Ethereum wallets and validating all transactions and contracts
          generated by this website before approval. Furthermore, as the
          DAOpunks Smart Contract runs on the Ethereum network, there is no
          ability to undo, reverse, or restore any transactions.
        </p>
        <p className="w-11/12 mx-10 max-w-4xl mt-5 sm:mt-10 ibm-plex text-base md:text-xl text-center text-white">
          This website and its connected services are provided “as is” and “as
          available” without warranty of any kind. By using this website you are
          accepting sole responsibility for any and all transactions involving
          DAOpunks digital collectibles.
        </p>
        <p className="w-11/12 mx-10 max-w-4xl mt-5 sm:mt-10 ibm-plex text-base md:text-xl text-center text-white">
          i. You Own the NFT. Each DAOpunk is an NFT on the Ethereum blockchain.
          When you purchase an NFT, you own the underlying DAOpunk, the Art,
          completely. Ownership of the NFT is mediated entirely by the Smart
          Contract and the Ethereum Network: at no point may we seize, freeze,
          or otherwise modify the ownership of any DAOpunk. If and when you sell
          your NFT on the Ethereum network, using a marketplace that permits the
          purchase and sale of your NFT, a royalty fee (also known as a “Creator
          Fee”) of up to 10% may be assigned as part of the transaction along
          with any other fee set by the marketplace.
        </p>
        <p className="w-11/12 mx-10 max-w-4xl mt-5 sm:mt-10 ibm-plex text-base md:text-xl text-center text-white">
          ii. Personal Use. Subject to your continued compliance with these
          Terms, the DAOpunks community grants you a worldwide, royalty-free
          license to use, copy, and display the purchased Art, along with any
          extensions that you choose to create or use, solely for the following
          purposes: (i) for your own personal, non-commercial use; (ii) as part
          of a marketplace that permits the purchase and sale of your DAOpunk /
          NFT, provided that the marketplace cryptographically verifies each
          DAOpunk owner’s rights to display the Art for their DAOpunk to ensure
          that only the actual owner can display the Art; or (iii) as part of a
          third party website or application that permits the inclusion,
          involvement, or participation of your DAOpunk, provided that the
          website/application cryptographically verifies each DAOpunk owner’s
          rights to display the Art for their DAOpunk to ensure that only the
          actual owner can display the Art, and provided that the Art is no
          longer visible once the owner of the DAOpunk leaves the
          website/application.
        </p>
        <p className="w-11/12 mx-10 max-w-4xl mt-5 sm:mt-10 ibm-plex text-base md:text-xl text-center text-white">
          iii. Commercial Use. Subject to your continued compliance with these
          Terms, the DAOpunks community grants you an unlimited, worldwide
          license to use, copy, and display the purchased Art for the purpose of
          creating derivative works based upon the Art (“Commercial Use”).
          Examples of such Commercial Use would e.g. be the use of the Art in
          providing “Goods and Services” that produce and sell merchandise
          products (Clothing, Souvenirs, and etc.) displaying copies of the Art
          or providing a service that displays copies of the Art. For the sake
          of clarity, nothing in this Section will be deemed to restrict you
          from (i) owning or operating a marketplace that permits the use and
          sale of DAOpunks generally, provided that the marketplace
          cryptographically verifies each DAOpunk owner’s rights to display the
          Art for their DAOpunk to ensure that only the actual owner can display
          the Art; (ii) owning or operating a third party website or application
          that permits the inclusion, involvement, or participation of DAOpunks
          generally, provided that the third party website or application
          cryptographically verifies each DAOpunk owner’s rights to display the
          Art for their DAOpunk to ensure that only the actual owner can display
          the Art, and provided that the Art is no longer visible once the owner
          of the Purchased DAOpunk leaves the website/application; or (iii)
          earning revenue from any of the foregoing.
        </p>
      </div>
    </div>
  );
}
