import React from "react";
import Header from "../components/global/header";
import BackgroundImage from "../assets/images/backgrounds/hero-background.png";
import anon from "../assets/images/iama/Anon-IAMA.png";
import shadowysupercoder from "../assets/images/iama/Shadowy_Super_Coder-IAMA.png";
import cryptonative from "../assets/images/iama/Crypto_Native-IAMA.png";
import creative from "../assets/images/iama/Creative-IAMA.png";
import iamalogo from "../assets/images/iama/IAMA-Logo-Black.png";
//god i hope this works.

const archetypes = [
  {
    name: "ANON",
    image: anon,
    mintURL: "https://app.manifold.xyz/c/I-AM-A-ANON",
    mintavailability: "Mint Closed.",
  },
  {
    name: "Shadowy Super Coder",
    image: shadowysupercoder,
    mintURL: "https://app.manifold.xyz/c/I-AM-A-SHADOWY-SUPER-CODER",
    mintavailability: "Mint Closed.",
  },
  {
    name: "Crypto Native",
    image: cryptonative,
    mintURL: "https://app.manifold.xyz/c/I-AM-A-CRYPTO-NATIVE",
    mintavailability: "Mint Closed.",
  },
  {
    name: "Creative",
    image: creative,
    mintURL: "https://app.manifold.xyz/c/I-AM-A-CREATIVE",
    mintavailability: "Mint Closed.",
  },  
];

export default function IAMA() {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%), url(${BackgroundImage})`,
      }}
      className="w-screen h-max bg-cover flex flex-col pb-20"
    >
      <Header />
     	<div className="flex flex-col items-center mt-10">
            <img className="w-80" src={iamalogo} alt="" />
	</div>    
        <div className="w-full flex flex-col justify-center items-center mt-10">
        <p className="w-11/12 mx-10 max-w-4xl mt-5 sm:mt-10 ibm-plex text-base md:text-xl text-center text-white">
          What makes someone a DAOpunk?
        </p>
        <p className="w-11/12 mx-10 max-w-4xl mt-5 sm:mt-10 ibm-plex text-base md:text-xl text-center text-white">
          Some may say it is because they own one of the 1,111 unique NFTs from our inital collection. I say that it is more of a calling, that only resonates with a certain few.
        </p>
        <p className="w-11/12 mx-10 max-w-4xl mt-5 sm:mt-10 ibm-plex text-base md:text-xl text-center text-white">
          I am here to tell you the stories of certain indivduals that heard this calling and found out who they truly are....What ARCHETPYE they became.
        </p>
        <p className="w-11/12 mx-10 max-w-4xl mt-5 sm:mt-10 ibm-plex text-base md:text-xl text-center text-white">
          I hope that their story echos something within you. So that you too can become a DAOpunk.
        </p>
	<p className="w-11/12 mx-10 max-w-4xl mt-5 sm:mt-10 ibm-plex text-base md:text-xl text-center text-white">
         
        </p>
        <div className="w-max grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-10 items-center justify-center">
          {archetypes.map((archetype, key) => {
            return (
              <div className="flex flex-col items-center" key={key}>
		<a href={archetype.mintURL}>
		<img className="w-80" src={archetype.image} alt=""/>
		</a>
                <div className="modius-bold text-xl text-white text-center mt-2">
                  <h1>{archetype.name}</h1>
                </div>
                <a
                  style={{ maxWidth: "15rem" }}
                  className="max-w-xs open-sans text-xl text-white text-center"
                  href={archetype.mintURL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {archetype.mintavailability}
                </a>
              </div>
            );
          })}
        </div>
	</div>  
      <h1 className="flex justify-center items-center text-white red-text-shadow modius-bold text-2xl md:text-5xl text-center mt-10 lg:mt-10 lg:mb-6">
        Roadmap
      </h1>
      <div className="w-full flex flex-col justify-center items-center mt-10">
       <p className="w-11/12 mx-10 max-w-4xl mt-5 sm:mt-10 ibm-plex text-base md:text-xl text-center text-white">
          - Release each of the Archetypes and their corresponding Merch
       </p>
        <p className="w-11/12 mx-10 max-w-4xl mt-5 sm:mt-10 ibm-plex text-base md:text-xl text-center text-white"> 
          - DpIP to establish voting rights
        </p>
	<p className="w-11/12 mx-10 max-w-4xl mt-5 sm:mt-10 ibm-plex text-base md:text-xl text-center text-white"> 
          - Potential Burning of I AM A NFTs
        </p>
	<p className="w-11/12 mx-10 max-w-4xl mt-5 sm:mt-10 ibm-plex text-base md:text-xl text-center text-white">
          - Create a Digital License Agreement
        </p>
	 <p className="w-11/12 mx-10 max-w-4xl mt-5 sm:mt-10 ibm-plex text-base md:text-xl text-center text-white">
          - Onboard new members into working in Web3
        </p>
	<p className="w-11/12 mx-10 max-w-4xl mt-5 sm:mt-10 ibm-plex text-base md:text-xl text-center text-white">
          - Create new DAOpunks programs
        </p>
	<p className="w-11/12 mx-10 max-w-4xl mt-5 sm:mt-10 ibm-plex text-base md:text-xl text-center text-white">
          - Fund more and bigger cohort rounds
        </p>
	</div>
	<h1 className="flex justify-center items-center text-white red-text-shadow modius-bold text-2xl md:text-5xl text-center mt-10 lg:mt-10 lg:mb-6">
        Digital License
      </h1>
      <div className="w-full flex flex-col justify-center items-center mt-10">
	<p className="w-11/12 mx-10 max-w-4xl mt-5 sm:mt-10 ibm-plex text-base md:text-xl text-center text-white">
      	Coming Soon
        </p>
	</div>
    </div>
    );
   }
