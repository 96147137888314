import React from "react";
import Header from "../components/global/header";
import BackgroundImage from "../assets/images/backgrounds/hero-background.png";
import HiroKennelly from "../assets/images/contributors/hirokenelly.png";
import LiveFast from "../assets/images/contributors/livefast.png";
import CryptoBushi from "../assets/images/contributors/thecryptobushi.png";
import MyCobici from "../assets/images/contributors/mycobici.png";
import CryptoSaurusD from "../assets/images/contributors/CryptosaurusD.png";
import Sinjun from "../assets/images/contributors/SinjunART.png";
import Yomi from "../assets/images/contributors/yomi.png";

const contributors = [
  {
    name: "Hirokennelly",
    image: HiroKennelly,
    twitterURL: "https://twitter.com/HiroKennelly",
    twitterHandle: "@HiroKennelly",
  },
  {
    name: "Livefast",
    image: LiveFast,
    twitterURL: "https://twitter.com/LiveFast9986",
    twitterHandle: "@LiveFast9986",
  },
  {
    name: "Mycobici",
    image: MyCobici,
    twitterURL: "https://twitter.com/mycobici",
    twitterHandle: "@mycobici",
  },
  {
    name: "GarrotTheParrot",
    image: CryptoSaurusD,
    twitterURL: "https://twitter.com/ParrotTheGarrot",
    twitterHandle: "@ParrotTheGarrot",
  },
  {
    name: "Sinjun",
    image: Sinjun,
    twitterURL: "https://twitter.com/SinjunART",
    twitterHandle: "@SinjunART",
  },
  {
    name: "Crypto Bushi",
    image: CryptoBushi,
    twitterURL: "https://twitter.com/thecryptobushi",
    twitterHandle: "@thecryptobushi",
  },
];
export default function Contributors() {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%), url(${BackgroundImage})`,
      }}
      className="w-screen h-max bg-cover flex flex-col pb-20"
    >
      <Header />
      <h1 className="flex justify-center items-center text-white red-text-shadow modius-bold text-2xl md:text-5xl text-center mt-10 lg:mt-10 lg:mb-6">
        MEET THE CONTRIBUTORS
      </h1>
      <div className="w-full flex flex-col justify-center items-center mt-10">
        <div className="w-max grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 items-center justify-center">
          {contributors.map((contributor, key) => {
            return (
              <div className="flex flex-col items-center" key={key}>
                <img className="w-80" src={contributor.image} alt="" />
                <div className="modius-bold text-xl text-white text-center mt-2">
                  <h1>{contributor.name}</h1>
                </div>
                <a
                  style={{ maxWidth: "15rem" }}
                  className="max-w-xs open-sans text-xl text-white text-center"
                  href={contributor.twitterURL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {contributor.twitterHandle}
                </a>
              </div>
            );
          })}
        </div>
        <div>
          <div className="flex flex-col items-center mt-10">
            <img className="w-80" src={Yomi} alt="" />
            <div className="modius-bold text-xl text-white text-center mt-2">
              <h1>Yomi</h1>
            </div>
            <a
              style={{ maxWidth: "15rem" }}
              className="max-w-xs open-sans text-xl text-white text-center"
              href="https://twitter.com/YOMI_WEB3"
              target="_blank"
              rel="noreferrer"
            >
              @YOMI_WEB3
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
