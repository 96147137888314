import "./App.css";
import Home from "./pages/home";
import Wallet from "./pages/wallet";
import NftGallery from "./pages/nft-gallery";
import Grants from "./pages/grants";
import Apply from "./pages/apply";
import License from "./pages/license";
import Contributors from "./pages/contributors";
import Cohort_Alumni from "./pages/cohort-alumni";
import IAMA from "./pages/iama";
import { BrowserRouter as Router, Route } from "react-router-dom";

function App() {
  console.log("Made with ❤️ by OverAchiever");
  return (
    <Router>
      <Route path="/gallery" component={NftGallery} />{" "}
      <Route path="/redeem" component={Wallet} exact />
      <Route path="/" component={Home} exact />
      <Route path="/grants" component={Grants} exact />
      <Route path="/grants/apply" component={Apply} exact />
      <Route path="/license" component={License} exact />
      <Route path="/contributors" component={Contributors} exact />
      <Route path="/alumni" component={Cohort_Alumni} exact />
      <Route path="/iama" component={IAMA} exact />
    </Router>
  );
}

export default App;
