import React from "react";
import Header from "../components/global/header";
import BackgroundImage from "../assets/images/backgrounds/hero-background.png";
import EllaDane from "../assets/images/cohort-alumni/EllaDane.jpg";
import Links from "../assets/images/cohort-alumni/Links.jpg";
import Marcus from "../assets/images/cohort-alumni/Marcus.png";
import Salmanneedsajob from "../assets/images/cohort-alumni/salmanneedsajob.png";
import Jomari from "../assets/images/cohort-alumni/Jomari.jpg";
import Tyler from "../assets/images/cohort-alumni/Tyler-TradFitoDeFi.jpg";
import Ernest from "../assets/images/cohort-alumni/ErnestOfGaia.png";
import Erinr from "../assets/images/cohort-alumni/Erinr-Eli5DAO.jpg";
import Sprinklesforwinners from "../assets/images/cohort-alumni/SprinklesForWinners.jpg";
import Ornellaweb3 from "../assets/images/cohort-alumni/OrnellaWeb3.jpg";
import D0wnlore from "../assets/images/cohort-alumni/d0wnlore.png";
import AllynBryce from "../assets/images/cohort-alumni/AllynBryce.png";
import Trewkat from "../assets/images/cohort-alumni/Trewkat.jpg";
import Saswatlens from "../assets/images/cohort-alumni/Saswatlens.png";
import Eurekajohn from "../assets/images/cohort-alumni/EurekaJohn.PNG";

const cohort_alumni = [
  {
    name: "Ella Dane",
    cohort: "cohort_0",
    image: EllaDane,
    twitterURL: "https://twitter.com/EllaDane_eth",
    twitterHandle: "@EllaDane_eth",
  },
  {
    name: "Links",
    image: Links,
    cohort: "cohort_0",
    twitterURL: "https://twitter.com/almithani",
    twitterHandle: "@almithani",
  },
  {
    name: "Marcus",
    cohort: "cohort_0",
    image: Marcus,
    twitterURL: "https://twitter.com/marcusdotam",
    twitterHandle: "@marcusdotam",
  },
  {
    name: "SalmanNeedsAJob",
    cohort: "cohort_1",
    image: Salmanneedsajob,
    twitterURL: "https://twitter.com/salmanneedsajob",
    twitterHandle: "@salmanneedsajob",
  },
  {
    name: "Jomari",
    cohort: "cohort_1",
    image: Jomari,
    twitterURL: "https://twitter.com/Jomari_P",
    twitterHandle: "@Jomari_P",
  },
  {
    name: "Tyler - TradFi to DeFi",
    cohort: "cohort_1",
    image: Tyler,
    twitterURL: "https://twitter.com/TradFitoDeFi",
    twitterHandle: "@TradFitoDeFi",
  },
  {
    name: "Ernest Of Gaia",
    cohort: "cohort_2",
    image: Ernest,
    twitterURL: "https://twitter.com/lotusdog",
    twitterHandle: "@lotusdog",
  },
  {
    name: "Erinr | ELI5 DAO ",
    cohort: "cohort_2",
    image: Erinr,
    twitterURL: "https://twitter.com/decentradiary",
    twitterHandle: "@decentradiary",
  },
  {
    name: "SprinklesForWinners",
    cohort: "cohort_2",
    image: Sprinklesforwinners,
    twitterURL: "https://twitter.com/BanklessWinners",
    twitterHandle: "@BanklessWinners",
  },  
  {
    name: "OrnellaWeb3",
    cohort: "cohort_3",
    image: Ornellaweb3,
    twitterURL: "https://twitter.com/OrnellaWeb3",
    twitterHandle: "@OrnellaWeb3",
  },
  {
    name: "d0wnlore",
    cohort: "cohort_3",
    image: D0wnlore,
    twitterURL: "https://twitter.com/d0wnlore",
    twitterHandle: "@d0wnlore",
  },   
  {
    name: "Allyn Bryce",
    cohort: "cohort_3",
    image: AllynBryce,
    twitterURL: "https://twitter.com/liquidiot",
    twitterHandle: "@Liquidiot",
  },
  {
    name: "Trewkat",
    cohort: "cohort_4",
    image: Trewkat,
    twitterURL: "https://twitter.com/trewkat",
    twitterHandle: "@trewkat",
  },
  {
    name: "Saswat.lens",
    cohort: "cohort_4",
    image: Saswatlens,
    twitterURL: "https://twitter.com/ArbalestPartner",
    twitterHandle: "@ArbalestPartner",
  },
  {
    name: "EurekaJohn",
    cohort: "cohort_4",
    image: Eurekajohn,
    twitterURL: "https://twitter.com/EurekaJohn1",
    twitterHandle: "@EurekaJohn1",
  }
];
export default function Cohort_Alumni() {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%), url(${BackgroundImage})`,
      }}
      className="w-screen h-max bg-cover flex flex-col pb-20"
    >
      <Header />
      <h1 className="flex justify-center items-center text-white red-text-shadow modius-bold text-2xl md:text-5xl text-center mt-10 lg:mt-10 lg:mb-6">
        MEET THE ALUMNI FROM OUR GRANTS PROGRAM
      </h1>
      <div className="w-full flex flex-col justify-center items-center mt-10">
        <div className="w-max grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 items-center justify-center">
          {cohort_alumni.map((alumni, key) => {
            return (
              <div className="flex flex-col items-center" key={key}>
                <img className="w-80" src={alumni.image} alt="" />
                <div className="modius-bold text-xl text-white text-center mt-2">
                  <h1>{alumni.name}</h1>
                  <h2>{alumni.cohort}</h2>
                </div>
                <a
                  style={{ maxWidth: "15rem" }}
                  className="max-w-xs open-sans text-xl text-white text-center"
                  href={alumni.twitterURL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {alumni.twitterHandle}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
